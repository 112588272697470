<template>
  <div id="agencies-create">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <agencies-form-create></agencies-form-create>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import AgenciesFormCreate from './components/AgenciesFormCreate'

  export default{
    name: 'AgenciesCreate',
    components: {AgenciesFormCreate},
    data() {
      return {
      }
    },
  }
</script>