<template>
  <div id="agencies-form-create">
    <b-form>
      <div class="divInfo" :style="sheet">
        <p class="flex"> <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1"/> Campos obligatorios (*), Resolución portada: 1366x768</p> 
      </div>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Logo*" label-for="logo">
          <b-form-file
              id="logo"
              type="file"
              browse-text="Formatos: svg"
              @change="_previewLogo"
              accept=".svg"
              placeholder="Selecciona una imagen"
              :state="logoErrors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ logoErrors[0] }}</small>

          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Portada*" label-for="cover_page">
          <b-form-file
              id="cover_page"
              type="file"
              browse-text="Formatos: jpeg - jpg - png"
              @change="_previewcover_page"
              accept=".jpg, .png, .svg"
              placeholder="Selecciona una imagen"
              :state="coverPageErrors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ coverPageErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Nombre de fantasía*" label-for="name">
            <b-form-input id="name"
              v-model="createForm.name"
              name="name"
              :state="nameErrors.length > 0 ? false:null"
              @input="$v.createForm.name.$touch()"/>
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Dirección*" label-for="address">
            <b-form-input id="address"
              v-model="createForm.address"
              name="address"
              :state="addressErrors.length > 0 ? false:null"
              @input="$v.createForm.address.$touch()"/>
            <small class="text-danger">{{ addressErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Razón social*" label-for="official_name">
            <b-form-input id="official_name"
              v-model="createForm.official"
              name="official_name"
              :state="officialErrors.length > 0 ? false:null"
              @input="$v.createForm.official.$touch()"/>
            <small class="text-danger">{{ officialErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Teléfono" label-for="phone">
            <b-form-input id="phone"
              v-model="createForm.phone"
              name="phone"
              :state="phoneErrors.length > 0 ? false:null"
              @input="$v.createForm.phone.$touch()"/>
            <small class="text-danger">{{ phoneErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="Contacto*" label-for="contact">
            <b-form-input id="contact"
              v-model="createForm.contact"
              name="contact"
              :state="contactErrors.length > 0 ? false:null"
              @input="$v.createForm.contact.$touch()"/>
            <small class="text-danger">{{ contactErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email"
              v-model="createForm.email"
              name="email"
              :state="emailErrors.length > 0 ? false:null"
              @input="$v.createForm.email.$touch()"/>
            <small class="text-danger">{{ emailErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" sm="12">
          <b-form-group label="URL Sitio" label-for="url">
            <b-form-input id="url"
              v-model="createForm.url_site"
              name="url"
              
              @input="$v.createForm.contact.$touch()"/>
            
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Orden*" label-for="orden">
            <b-form-input id="orden" 
              v-model="createForm.orden"
              name="orden"
              :state="ordenErrors.length > 0 ? false:null"
              @input="$v.createForm.orden.$touch()"
              /> 
              <small class="text-danger">{{ ordenErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="6" sm="12">
          <b-form-group label="Descripción de la inmobiliaria" label-for="description">
            <b-form-textarea
              v-model="createForm.description"
              id="description"
              rows="3"/>
          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Cantidad de planes" label-for="Cantidad de planes">
            <b-form-input id="Restricciones"
              v-model="createForm.restriction_quantity"
              name="restriction_quantity"
              :state="restrictionQuantityErrors.length > 0 ? false:null"
              @input="$v.createForm.restriction_quantity.$touch()"
              /> 
              <small class="text-danger">{{ restrictionQuantityErrors[0] }}</small>
              
             </b-form-group>
        </b-col>
 
        
      </b-row>

      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()">
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_createAgency()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                  Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required, numeric, email, maxLength} from 'vuelidate/lib/validators'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default{
    name: 'AgenciesFormCreate',
    data() {
      return {
        overlayForm: false,
        logoAgency: '',
        logoAgency: '',
        cover_pageAgency: '',
        createForm: {
          name: '',
          orden: '',
          address: '',
          official: '',
          phone: '',
          contact: '',
          email: '',
          description: '',
          logo: [],
          cover_page:[],
          restriction_quantity:0
        },
        sheet:{
          backgroundColor:"#EFEFEF",
        },
      }
    },
    validations: {
      createForm: {
        name: {
          required,
        },
        address: {
          required,
        },
        official: {
          required,
        },
        phone: {
          numeric,
          maxLength: maxLength(9)
        },
        orden: {
          required,
          numeric
        },
        contact: {
          required,
        },
        email: {
          email,
        },
        cover_page:{required},
        logo:{required},
        restriction_quantity:{
          required,
          numeric
        }
      },
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.createForm.name.$dirty) return errors
        !this.$v.createForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      addressErrors () {
        const errors = []
        if (!this.$v.createForm.address.$dirty) return errors
        !this.$v.createForm.address.required && errors.push('La dirección es obligatoria')
        return errors
      },
      officialErrors () {
        const errors = []
        if (!this.$v.createForm.official.$dirty) return errors
        !this.$v.createForm.official.required && errors.push('La razón social es obligatoria')
        return errors
      }, 
      ordenErrors () {
        const errors = []
        if (!this.$v.createForm.orden.$dirty) return errors
        !this.$v.createForm.orden.required && errors.push('El orden es obligatorio')
        !this.$v.createForm.orden.numeric && errors.push('El orden debe ser numerico')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.createForm.phone.$dirty) return errors
        !this.$v.createForm.phone.numeric && errors.push('El teléfono debe ser numerico')
        !this.$v.createForm.phone.maxLength && errors.push('El teléfono debe contener máximo 9 dígitos')
        return errors
      },
      contactErrors () {
        const errors = []
        if (!this.$v.createForm.contact.$dirty) return errors
        !this.$v.createForm.contact.required && errors.push('El nombre de contacto es obligatoria')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.createForm.email.$dirty) return errors
        !this.$v.createForm.email.email && errors.push('El email debe ser valido')
        return errors
      },
      descriptionErrors () {
        const errors = []
        if (!this.$v.createForm.description.$dirty) return errors
        !this.$v.createForm.description.required && errors.push('La descripción es obligatoria')
        return errors
      },
      coverPageErrors () {
        const errors = []
        if (!this.$v.createForm.cover_page.$dirty) return errors
        !this.$v.createForm.cover_page.required && errors.push('La portada es obligatoria')
        return errors
      },
      logoErrors () {
        const errors = []
        if (!this.$v.createForm.logo.$dirty) return errors
        !this.$v.createForm.logo.required && errors.push('El logo es obligatorio')
        return errors
      },
      restrictionQuantityErrors () {
        const errors = []
        if (!this.$v.createForm.restriction_quantity.$dirty) return errors
        !this.$v.createForm.restriction_quantity.required && errors.push('La cantidad de restriccion es obligatoria')
        !this.$v.createForm.restriction_quantity.numeric && errors.push('La cantidad de restriccion debe ser numerico')
        return errors
      },
      
    },
    methods: {
      ...mapActions('agencies',['createAgencies']),
      async _createAgency() {
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          await this.createAgencies(this.createForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Inmobiliaria registrada con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.createForm =  {
              name: '',
              address: '',
              orden: '',
              official: '',
              phone: '',
              contact: '',
              email: '',
              description: '',
              logo: [],
              cover_page: [],
              restriction_quantity:0
            }
            this.$v.createForm.$reset()
            this.$router.push({ name: 'agencies'})
            }).catch(err => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Problemas al registrar la inmobiliaria',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
                this.overlayForm = false
            })
        }
      },
      _previewLogo(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/svg+xml'){
            this.logoAgency = URL.createObjectURL(event.target.files[0])
            this.createForm.logo = event.target.files[0]
          }else{
            document.getElementById("logo").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
        }
      },
      _previewcover_page(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.cover_pageAgency = URL.createObjectURL(event.target.files[0])
            this.createForm.cover_page = event.target.files[0]
          }else{
            document.getElementById("cover_page").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
        }
      },
      _onHidden() {
        if(this.$refs.button != null){
          this.$refs.button.focus()
        }
      },
    },
  }
</script>

<style>
    .divInfo {
        border-radius: 10px
    }
</style>
